.react-international-phone-input-container {
  position: relative !important;
}

.react-international-phone-country-selector {
  position: absolute !important;
}
.react-international-phone-country-selector button {
  border: none !important;
  border-right: 1px solid #e5e5e5 !important;
  background-color: transparent !important;
  padding: 8px 8px 8px 16px !important;
  height: 64px !important;
}

.react-international-phone-input {
  padding: 20px 16px 20px 78px !important;
  font-size: 16px !important;
  height: auto !important;
  border-radius: 16px !important;
  width: 100% !important;
}

.react-international-phone-input.floatingLabelOnTop {
  padding: 28px 16px 12px 78px !important;
  border: 1px solid theme('colors.hg.black') !important;
}
